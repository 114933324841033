import React, { useContext } from "react";
import { Context as OrderContext } from "../../context/OrderContext";
import { ordersOptimization } from "../../api/functions";
import MaterialTable from 'material-table';



export default () => {

  const { state } = useContext(OrderContext);
  const analysis = ordersOptimization(state.orders);
  const text = state.text;
  

  const tableData = Object.keys(analysis).map(id => {
    return { id, queue: analysis[id]["Queue"], inProgress: analysis[id]["In progress"], total: (analysis[id]["Queue"] + analysis[id]["In progress"]) };
  });

  return (
    <MaterialTable
      title={""}
      columns={[
        { field: 'id', title: text["ID"] },
        { field: 'queue', title: text["Queue"], type: 'numeric' },
        { field: 'inProgress', title: text["In progress"], type: 'numeric' },
        { field: 'total', title: text["Total"], type: 'numeric' },

      ]}
      data={tableData}
     
      options={{
        headerStyle: {
          backgroundColor: '#0f61d4',
          color: '#FFF'
      },
        exportButton: true
      }}

    />
  );
}


