import React, { useContext } from "react";
import { Link } from "react-router-dom";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardFooter from "components/Card/CardFooter.js";
import Info from "components/Typography/Info.js";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import Icon from "@material-ui/core/Icon";
import { CustomIcon } from "../Icons/CustomIcon";
import AccessTime from "@material-ui/icons/AccessTime";
import { makeStyles } from "@material-ui/core/styles";
import { Context as OrderContext } from "../../context/OrderContext";
import { moment } from "../../api/functions";
import WaitngTime from "./waitingTime";

const useStyles = makeStyles(styles);

export default ({ color, type }) => {
    const classes = useStyles();
    const { state } = useContext(OrderContext);
    const text = state.text;



    const getQueueId = () => {
        let queue;
        switch (type) {
            case "Queue":
                queue = 0;
                break;
            case "In progress":
                queue = 1;
                break;
            case "Completed":
                queue = 2;
                break;
            case "Billing":
                queue = 3;
                break;
            default:
                queue = 0;
        }
        return queue;
    }

    const id = getQueueId();




    return (
        <Card>
            <CardHeader color={color} stats icon>
                <CardIcon color={color}>
                    <CustomIcon type={type} />
                </CardIcon>
                <p className={classes.cardCategory}>{text[type]}</p>
                <h3 className={classes.cardTitle}>
                    {state.orders.filter(order => {

                        if (type === "Billing") {
                            return order.status === "Completed"
                        } else {
                            return order.status === type;
                        }

                    }).length}
                </h3>
            </CardHeader>
            <CardFooter stats>
                {
                    (type === "Completed" || type === "Billing") && <div className={classes.stats}>
                        <AccessTime />{text["Last update"]}: {moment(state.completedTimestamp).calendar()}
                    </div>
                }
                {
                    (type !== "Completed" && type !== "Billing") && <div className={classes.stats}>
                        <AccessTime />{text["Waitng time"]}:  <WaitngTime orders={state.orders.filter(order => order.status === type)} />
                    </div>
                }
                <Link to={`/admin/orders/${id}`}>
                    <Info>
                        <Icon>launch</Icon>
                    </Info>
                </Link>
            </CardFooter>
        </Card>
    );
}