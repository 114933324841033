import React, { useContext } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import QueueDetails from "../Orders/queueDetails";
import { Context as OrderContext } from "../../context/OrderContext";
import useDatabase from "../../hooks/useDatabase";


const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

const useStyles = makeStyles(styles);

export default function TableList(props) {
  useDatabase();
  const { state } = useContext(OrderContext);
  const text = state.text;
  let selectedQueue = 0;
  try{
   selectedQueue = parseInt(props.location.pathname.split("/")[3]);
   if(selectedQueue > 3 || isNaN(selectedQueue) || selectedQueue < 0){
     selectedQueue = 0;
   }
  }catch(err){
    selectedQueue = 0;
  }

  const classes = useStyles();


  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <QueueDetails selected ={selectedQueue}/>
      </GridItem>
    </GridContainer>
    
  );
}
