import React, { useContext } from "react";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import { Context as OrderContext } from "../../context/OrderContext";
import QueueIcon from "@material-ui/icons/Queue";
import ProgressIcon from "@material-ui/icons/Schedule";
import CompletedIcon from "@material-ui/icons/AssignmentTurnedIn";
import Transaction from "../Transaction/TransanctionTable";
import ReceiptIcon from "@material-ui/icons/Receipt";
import QueueTable from "../Orders/QueueTable";

export default ({ selected = 0 }) => {

    const { state } = useContext(OrderContext);
    const text = state.text;

    return (
        <CustomTabs

            headerColor="rose"
            selected={selected}
            tabs={[
                {
                    tabName: text["Queue"],
                    tabIcon: QueueIcon,
                    tabContent: (
                        <QueueTable name="Queue" />
                    )
                },
                {
                    tabName: text["In progress"],
                    tabIcon: ProgressIcon,
                    tabContent: (
                        <QueueTable name="In progress" />
                    )
                },
                {
                    tabName: text["Completed"],
                    tabIcon: CompletedIcon,
                    tabContent: (
                        <QueueTable name="Completed" />
                    )
                }, {
                    tabName: text["Billing"],
                    tabIcon: ReceiptIcon,
                    tabContent: (
                        <Transaction />
                    )
                }
            ]}
        />
    );
}


