import React, { useEffect, useState } from "react";
import { calculateWaitngTime } from "../../api/functions";

export default ({ orders}) => {
    const [waitingTime, setWaitngTime] = useState(0)
    
    useEffect(() => {
        setWaitngTime(calculateWaitngTime(orders, Date.now()));
        const interval = setInterval(() => {
            setWaitngTime(calculateWaitngTime(orders, Date.now()));
        }, 60000);

        return () => {
            clearInterval(interval)
        }
    }, [orders])

    return (
        <div>
        &nbsp;{waitingTime} min
        </div>
    )
}