import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';

const firebaseConfig = {
   apiKey: "AIzaSyBmRBK7b1coIOB2gjFiJZ0-UlwT3azY2CI",
   authDomain: "v-waiter.firebaseapp.com",
   databaseURL: "https://v-waiter.firebaseio.com",
   projectId: "v-waiter",
   storageBucket: "v-waiter.appspot.com",
   messagingSenderId: "384460345789",
   appId: "1:384460345789:web:34b1fc2035dcdf022f42d4"
};

const app = firebase.initializeApp(firebaseConfig);

export const database = app.database();

export default app.auth();

export const loginWithGoogle = async () => {
   const provider = new firebase.auth.GoogleAuthProvider();
   firebase.auth().useDeviceLanguage();
   try {
      const result = await firebase.auth().signInWithPopup(provider);
      return result;
   }
   catch (err) {
      return false;
   }
}

export const loginWithPassword = async (email, password) => {
   const user = firebase.auth().currentUser;
   try {
      if (user) {
         await firebase.auth().currentUser.updatePassword(password);
         return { outcome: true, message: "Login successfully" };
      }else{
         await firebase.auth().signInWithEmailAndPassword(email, password);
         return { outcome: true, message: "Login successfully" };
      }
   }
   catch (err) {
      return { outcome: false, message: "Access denied" };
   }
}



export const emailLinkSignup = async (email) => {

   const actionCodeSettings = {
      // URL you want to redirect back to. The domain (www.example.com) for this
      // URL must be whitelisted in the Firebase Console.
      url: 'http://localhost:3000/signup',
      // This must be true.
      handleCodeInApp: true,
   };

   try {
      await firebase.auth().sendSignInLinkToEmail(email, actionCodeSettings);
      localStorage.setItem('email', email);
      return { outcome: true, message: "Please check your email inbox to complete the registration" };

   } catch (err) {
      return { outcome: false, err };
   }

}
