import React, { useContext, useEffect, useState } from 'react';
import MaterialTable from 'material-table';
import { Context as OrderContext } from "../../context/OrderContext";
import { orderItemList, waitingTime } from "../../api/functions";
import OrderItemDialog from "../../components/Dialog/OrderItemDialog";


export default ({ name }) => {
    const { state,setSelectedOrder } = useContext(OrderContext);
    const { category, id, items } = orderItemList(state.orders, name);
    const [timestamp, setTimestamp] = useState(Date.now());
    const [isOpen,setIsOpen] = useState(false);
    const text = state.text;

    const categoryLookup = category.reduce((lookup,value,index)=>{
        lookup[index] = value;
        return lookup
    },{});

   

    const data = items.map(item => {
        return {
            table: item.table,
            title: item.item.id,
            category: category.indexOf(item.item.category),
            waitng: waitingTime(timestamp, item.orderId),
            item
        };
    })

    

    useEffect(() => {
        const myInterval = setInterval(() => {
            setTimestamp(Date.now());
        }, 300000);

        return () => {
            clearInterval(myInterval)
        }
    }, []);

    return (
        <div>
        <MaterialTable
            title={text[name]}
            columns={[
                { title: 'Table', field: 'table' },
                {
                    title: 'Title',
                    field: 'title',
                    lookup: id
                },
                {
                    title: 'Category',
                    field: 'category',
                    lookup: categoryLookup
                },
                {
                    title: 'Waiting(min)',
                    field: 'waitng',
                    type: 'numeric',
                    customFilterAndSearch: (term, rowData) => rowData.waitng >= term

                },
            ]}
            data={data}

            options={{
                filtering: true,
                exportButton: true,
                grouping: true,
                headerStyle: {
                    backgroundColor: '#0f61d4',
                    color: '#FFF'
                },
            }}

            actions={[
                {
                  icon: 'more_vert',
                  tooltip: 'Details',
                  onClick: (event, rowData) => {
                    setSelectedOrder({...rowData.item})
                    setIsOpen(true);
                  }
                }
              ]}
        />
        <OrderItemDialog onClose={()=>setIsOpen(false)} open={isOpen}/>
        </div>
    )
}
