import React from "react";
import { Route, Redirect } from "react-router-dom";
import auth from "../firebase/firebase";

export default ({ component: Component, ...rest }) => {
  
    return (
       
        (!!auth.currentUser) ? (<Route {...rest} component={(props) => (
            <Component {...props} />)} />) : (<Redirect to="/login" />)
    );

}