import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import auth from "../firebase/firebase";
import { Context as OrderContext } from "../context/OrderContext";


export default ({ component: Component, ...rest }) => {
    const { state } = useContext(OrderContext);
    let destination;


    if(!!auth.currentUser && state.client.id){
        destination = "/admin/dashboard";
    }else if(!!auth.currentUser){
        destination = "/accounts";
    }
    
    return (
        (!!auth.currentUser) ? (<Redirect to={destination} />) : (<Route {...rest} component={(props) => (
            <Component {...props} />)} />)
    );

}