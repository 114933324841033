import { useContext, useEffect } from "react";
import { Context as MenuContext } from "../context/OrderContext";
import { database } from "../firebase/firebase";
import auth from "../firebase/firebase";
import history from "../context/HistoryContext";
import {orderStatus} from "../api/functions";


export default () => {
    const { state, setClient, updateMenuChanges, loadOrders, addOrder, removeOrder, modifyOrder, setCompletedTimestamp} = useContext(MenuContext);

    
    useEffect(() => {
        let ref;
        let menuRef;

        if(!state.client.id){
          setClient(JSON.parse(sessionStorage.getItem("client")));
        }

        try {
            if (state.client && auth.currentUser) {
                ref = database.ref(`restaurant/${state.client.id}/orders`);
                menuRef = database.ref(`restaurant/${state.client.id}/menu`);

                ref.once("value").then((snapshot) => {
                    let orders = []
                    snapshot.forEach((childSnapshot) => {
                        orders.push({
                            ...childSnapshot.val(), id: childSnapshot.key
                        });
                    });
                    
                    const maxRetention = 4;
                
                    orders = orders.map(order =>({...order,status:orderStatus(order)}))
                    .filter(order =>{
                        const timestamp =parseInt(order.id.split("@")[0]);
                        const elapsedHour = (Date.now() - timestamp)/(1000*60*60);
                        return !(elapsedHour > maxRetention && order.status === "Completed");
                    });
                    loadOrders(orders);
                }).catch((err) => {

                });

                ref.on("child_changed", (snapshot) => {
                    let order = { ...snapshot.val(), id: snapshot.key };
                    const status = orderStatus(order);
            
                    order.status = status;
                    modifyOrder(order);
                    if (order.status === "Completed") {
                        setCompletedTimestamp(Date.now());
                    }
                }, (err) => {

                });

                ref.on("child_added", (snapshot) => {

                    let order = { ...snapshot.val(), id: snapshot.key };
                    order ={...order,status:orderStatus(order)};
                    addOrder(order);


                }, (err) => {

                });

                ref.on("child_removed", (snapshot) => {

                    const order = {
                        id: snapshot.key
                    };
                    removeOrder(order);

                }, (err) => {

                });




                menuRef.on("value", (snapshot) => {
                    if (snapshot.val()) {
                        const { items, settings } = snapshot.val();
                        updateMenuChanges({ items, settings });
                    }else{
                        history.push("/login");
                    }

                }, (err) => {

                });


                return () => {
                    ref.off();
                    menuRef.off();
                };

            } else {
                history.push("/login");
            }

        } catch (err) {
            if (ref) {
                ref.off();
            }

            if (menuRef) {
                menuRef.off();
            }
        }

    }, [state.client]);
}