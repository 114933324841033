import React from "react";
import { PayPalButton } from "react-paypal-button-v2";
import auth from "../../firebase/firebase";
import history from "../../context/HistoryContext";
import { createNewAccount } from "../../firebase/database";

export default ({ newAccount }) => {
  return (
    <PayPalButton
      options={{
        vault: true,
        clientId: "ATJqWlKa0vWuVI_iAO608wSA8_1rEJPNkhJ6pe4N5QBJYq14L_p6uDfIMY0gi_5o08lFAoMu5k8_r-Xa"
      }}
      createSubscription={(data, actions) => {
        return actions.subscription.create({
          plan_id: 'P-8GN73649R5187184KL4NVZDI'
        });
      }}
      onApprove={(data, actions) => {
        // Capture the funds from the transaction
        return actions.subscription.get().then(function (details) {
          // Show a success message to your buyer
          if (newAccount) {
            createNewAccount(auth.currentUser.email, auth.currentUser.uid,details).then((result) => {
              if (result.outcome) {
                history.push("/accounts");
                alert("Subscription completed");
              } else {
                alert("Error 5001: occurred while creating a new account. Please contact customer service");
                /*Send creating account error to subscription log with the user 
                and subscription details for maunal creation. */
              }
            })
          } else {

            //Handle subscription update

          }

          console.log(details)
          // OPTIONAL: Call your server to save the subscription

        });
      }}
    />
  );
}