import { database } from "./firebase";

const CLIENT_ID = "qtOcurf1zoR5eZSlirJkPeD94F43";
const LOGO = "https://static.wixstatic.com/media/74953f_626b4f8c53ee47469e1b04523bb8b0dc~mv2.png";
const BUSINESS_NAME = "V-Waiter"

export const getAccounts = async (email) => {
    const formattedEmail = email.replace("@", "__").replace(".", "--");
    const ref = database.ref(`restaurant/users/${formattedEmail}/accounts`);
    let values = [];
    let accounts = []
    try {
        let snapshot = await ref.once("value");

        snapshot.forEach((child) => {
            const id = child.key;
            values.push(id);
        });

        for (let i = 0; i < values.length; i++) {
            snapshot = await database.ref(`restaurant/${values[i]}/menu/settings`).once("value");
            const { logo, businessName, clientId } = snapshot.val();
            accounts.push({ logo, name: businessName, id: clientId })
        }

    } catch (err) {

    }

    return { values: accounts };
}




export const updateStatus = async (clientId, orderId, status) => {
    
    try {
        await database.ref(`restaurant/${clientId}/orders/${orderId}/status`).set(status);
       
        return { success: true };
    } catch (err) {
        return { success: false };
    }
}

export const updateMenu = async (clientId, menu) => {

    try {
        await database.ref(`restaurant/${clientId}/menu`).update({ items: menu });
        return { success: true };
    } catch (err) {
        return { success: false };
    }
}


export const updateBusinessName = async (clientId, businessName) => {

    try {
        await database.ref(`restaurant/${clientId}/menu/settings`).update({ businessName });
        return { success: true };
    } catch (err) {
        return { success: false };
    }
}

export const updateLogo = async (clientId, logo) => {
    try {
        await database.ref(`restaurant/${clientId}/menu/settings`).update({ logo });
        return { success: true };
    } catch (err) {
        return { success: false };
    }
}

export const updateTax = async (clientId, tax) => {
    try {
        const rate = parseFloat(tax);
        await database.ref(`restaurant/${clientId}/menu/settings`).update({ tax:rate });
        return { success: true };
    } catch (err) {
        return { success: false };
    }
}


export const createNewAccount = async (email, uid, subscription = {}) => {
    try {
        let snapshot = await database.ref(`restaurant/${CLIENT_ID}/menu`).once("value");
        const items = snapshot.val().items;
        snapshot = await database.ref(`restaurant/${uid}/menu`).set({ items });
        const clientId = uid;
        await database.ref(`restaurant/${clientId}/menu`)
            .update({ clientId, settings: { subscription, admin: email, clientId, logo: LOGO, businessName: BUSINESS_NAME } });

        const formatedEmail = email.replace("@", "__").replace(".", "--");
        const account = { email, clientId, role: "admin" };
        await database.ref(`restaurant/users/${formatedEmail}/accounts/${clientId}`).set(account)
        return { outcome: true }

    } catch (err) {
        return { outcome: false, err }
    }

}


export const addUser = async (clientId, email, role = "admin") => {
    try {
        const ref = database.ref(`restaurant/${clientId}/menu/settings/${role}`);
        const snapshot = await ref.once("value");
        let admin = snapshot.val();

        if (!admin.includes(email)) {
            admin += "," + email;
        }

        await ref.set(admin);

        const formatedEmail = email.replace("@", "__").replace(".", "--");
        const account = { email, clientId, role };
        await database.ref(`restaurant/users/${formatedEmail}/accounts/${clientId}`).set(account)
        return { outcome: true }
    } catch (err) {
        return { outcome: false, err }
    }


}

export const updateOrderItem = async (clientId,orderId,itemIndex,item) =>{
    try {
        await database.ref(`restaurant/${clientId}/orders/${orderId}/items/${itemIndex}`).set(item);
        return { success: true };
    } catch (err) {
        return { success: false };
    }
}


