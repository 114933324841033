import React,{useContext} from 'react';
import MaterialTable from 'material-table';
import { Context as OrderContext } from "../../context/OrderContext";
import {calculateBillis,formatPrice} from "../../api/functions";
import Details from "./TransactionDetails";

export default () => {

    const { state } = useContext(OrderContext);
    const bills = calculateBillis(state.orders.filter(order => order.status ==="Completed")) || [];
    const data = bills.map(bill =>{
        return { table: bill.tableId, total: formatPrice(`${bill.transaction.total} ${bill.transaction.currency}`), customers: bill.subTransactions.length };
    });

  

    return (
        <MaterialTable
            title="Billing"
            columns={[
                { title: 'Table', field: 'table' },
                { title: 'Total', field: 'total' },
                { title: 'Customers', field: 'customers', type: 'numeric' }
            ]}
            data={data}
            actions={[
                {
                    icon: 'print',
                    tooltip: 'Print receipt',
                    onClick: (event, rowData) => alert("You saved " + rowData.name)
                }
            ]}

            options={{
                headerStyle: {
                    backgroundColor: '#0f61d4',
                    color: '#FFF'
                },
                exportButton: true
            }}

            detailPanel={rowData => {
                const result = bills.find( x => x.table === rowData.tableId);
                return (
                  <Details tax ={state.menu.settings.tax}  subTransactions = {result.subTransactions}/>
                )
              }}
        />
    )
}