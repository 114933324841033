
import moment from 'moment'




const default_language = "en";
let locale = default_language;
const message = new Map();
const en = {};
message[default_language] = en;


export const Text = (message) => {
    let result = null;
    try {
        result = message[locale][message];
        result = (!!result) ? result : message[default_language][message];
    } catch (err) {
        result = message[default_language][message];
    }
    return result;
}

export const addLanguage =(locale,language_map) =>{
    message[locale] = language_map;
}


export const setLocale = (value) =>{
    locale = value;
    return locale;

}

export const localeMessage = (locale) =>{
    const result  = message[locale];
    return (!!result) ? result : message[default_language]; 
}



export const localeMoment = () =>{
    return moment;
}

en["Sign in"] ="Sign In";
en["Login"] ="Login";
en["Email Address"] = "Email Address";
en["Remember me"] = "Remember me";
en["Forgot password?"] = "Forgot password?";
en["Password"] = "Password";
en["no_account_signup"] = "Don't have an account? Sign Up";
en["have_account_login"] = "Have an account? Login";
en["Queue"] = "Queue";
en["In progress"] = "In Progress";
en["Completed"] = "Completed";
en["Hailing"] = "Hailing";
en["Waitng time"] = "Waitng time";
en["Tasks"] = "Tasks";
en["ID"] = "ID";
en["Quantity"] = "Quantity";
en["Title"] = "Title";
en["Price"] = "Price";
en["Table"] = "Table";
en["Edit order"] = "Edit Order";
en["Delete order"] = "Delete order";
en["Menu upload/update"] = "Menu upload/update";
en["upload_title"] = "Please upload .csv file only";
en["droppzone_title"] = "Drop the file here";
en["droppzone_title_2"] = "Drag 'n' drop some file here, or click to select files";
en["Save"] = "Save";
en["Options"] = "Options";
en["Total"] = "Total";
en["Orders overview"] = "Orders overview";
en["Orders_overview_title"] = "Items in Queue and In progress";
en["Top 10"] = "Top 10";
en["min"] = "min";
en["Queue Orders"] = "Queue Orders";
en["In progress Orders"] = "In progress Orders";
en["Completed Orders"] = "Completed Orders";
en["order increase in the last hour"] = "order increase in the last hour";
en["order decrease in the last hour"] = "order decrease in the last hour";
en["Notification"] = "Notification";
en["Dashboard"] = "Dashboard";
en["Settings"] = "Settings";
en["Logout"] = "Logout";
en["Profile"] = "Profile";
en["Last update"] = "Last update";
en["Orders Manager"] = "Orders Manager";
en["Orders Analysis"] = "Orders Analysis";
en["Business Profile"] = "Business Profile";
en["Upgrade To PRO"] = "Upgrade To PRO";
en["Tutorial"] = "Tutorial";
en["RTL Support"] = "RTL Support";
en["Confirm Password"] = "Confirm Password";
en["Create Account"] = "Create Account";
en["Accounts Overview"] = "Accounts Overview";
en["Accounts_overview_title"] = "Create a new account or open an existing account";
en["Admin dashboard"] = "Admin dashboard";
en["email_confirmation"] = "Please provide your email for confirmation";
en["Accounts"] = "Accounts";
en["Billing"] = "Billing";














