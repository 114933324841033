import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
// @material-ui/icons
import Edit from "@material-ui/icons/Launch";
// core components
import styles from "assets/jss/material-dashboard-react/components/tasksStyle.js";
import { Context as OrderContext } from "../../context/OrderContext";
import history from "../../context/HistoryContext";
import auth from "../../firebase/firebase";
import { getAccounts } from "../../firebase/database";
import Avatar from '@material-ui/core/Avatar';


const useStyles = makeStyles(styles);

export default function Tasks(props) {

    const { state, setClient, setUser, setAccounts } = useContext(OrderContext);
    const text = state.text;
    const classes = useStyles();


    const tableCellClasses = classnames(classes.tableCell, {
        [classes.tableCellRTL]: props.rtlActive
    });

    const user = auth.currentUser;

    useEffect(() => {
        if (user) {
            const email = user.email;
            (async () => {
                const accounts = await getAccounts(email);

                if (accounts.values.length > 0) {
                    setAccounts(accounts.values);
                    setUser({ email })
                } else {
                    setAccounts([]);
                }
            })()
        }
    }, [user]);



    return (
        <Table className={classes.table}>
            <TableBody>
                {state.accounts.map((value, index) => (
                    <TableRow key={index} className={classes.tableRow}>
                        <TableCell className={tableCellClasses}>
                            <Avatar>
                                <img width="40" src={value.logo} alt="logo" />
                            </Avatar>
                        </TableCell>
                        <TableCell className={tableCellClasses}>{value.name}</TableCell>
                        <TableCell className={classes.tableActions}>
                            <Tooltip
                                id="tooltip-top"
                                title={text["Admin dashboard"]}
                                placement="top"
                                classes={{ tooltip: classes.tooltip }}
                            >
                                <IconButton
                                    aria-label="Edit"
                                    className={classes.tableActionButton}
                                    onClick={() => {
                                        setClient(value);
                                        sessionStorage.setItem("client", JSON.stringify(value));
                                        history.push("/admim/dashboard");
                                    }}
                                >
                                    <Edit
                                        className={
                                            classes.tableActionButtonIcon + " " + classes.edit
                                        }
                                    />
                                </IconButton>
                            </Tooltip>

                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
}

Tasks.propTypes = {
    tasksIndexes: PropTypes.arrayOf(PropTypes.number),
    tasks: PropTypes.arrayOf(PropTypes.node),
    rtlActive: PropTypes.bool,
    checkedIndexes: PropTypes.array
};

