import React, { useState, useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import SaveIcon from '@material-ui/icons/SaveOutlined';
import { Context as OrderContext } from "../../context/OrderContext";
import { waitingTime } from "../../api/functions";
import TextField from '@material-ui/core/TextField';


const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 500,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  avatar: {
    backgroundColor: "#506cfa",
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  spacing: {
    display: "flex",
    flexDirection: "row",
    flexWrap: 'wrap',
  }
}));

export default function RecipeReviewCard() {
  const classes = useStyles();
  const { state, updateSelectedOrder } = useContext(OrderContext);
  const text = state.text;
  const item = state.selectedOrder;
  const [instructions, setInstructions] = useState(item.item.instructions);
  const [quantity, setQuantity] = useState(item.item.quantity);
  const [isDisabled, setIsDisabled] = useState(true);



  const waiting = waitingTime(Date.now(), item.orderId);

  useEffect(() => {
    handleChanges(quantity, instructions, item.item);
  }, [quantity, instructions, item.item])

  const handleChanges = (quantity, instructions, item) => {

    if (!!item.instructions) {
      if ((instructions === item.instructions) && (quantity === item.quantity)) {
        setIsDisabled(true);
      } else {
        setIsDisabled(false);
      }
    } else {
      if (!!instructions || (quantity !== item.quantity)) {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }
    }

  }



  const updateItem = () => {
    item.item.quantity = parseInt(quantity);
    item.item.instructions = instructions;
    updateSelectedOrder({ item: item.item })

  }

  return (
    <Card className={classes.root}>
      <CardHeader
        avatar={
          <Avatar src={item.item.imageURL} aria-label="recipe" className={classes.avatar} />
        }
        action={
          <IconButton aria-label="settings">
            <MoreVertIcon />
          </IconButton>
        }
        title={item.item.title}
        subheader={`${waiting} min`}
      />

      <CardContent>
        <div className={classes.root}>
          <TextField
            className={classes.spacing}
            id="standard-number"
            label="Quantity"
            type="number"
            value={quantity}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{ min: 0, max: 100 }}
            variant="outlined"
            onChange={(e) => {
              setQuantity(e.target.value);
            }}
          />
          <TextField
            className={classes.spacing}
            id="standard-multiline-flexible"
            label="Instructions"
            multiline
            rowsMax={4}
            value={instructions}
            onChange={(e) => {
              setInstructions(e.target.value);
            }}
          />
        </div>
      </CardContent>
      <CardActions disableSpacing>
        <Button
          variant="contained"
          color="primary"
          size="small"
          className={classes.button}
          startIcon={<SaveIcon />}
          disabled={isDisabled}
          onClick={() => updateItem()}
        >
          Save
      </Button>
      </CardActions>

    </Card>
  );
}
