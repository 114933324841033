import React, { useCallback, useContext, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { useDropzone } from 'react-dropzone';
import * as csv from "csvtojson";
import { Context as OrderContext } from "../../context/OrderContext";
import MenuItem from "../Menu/MenuItem";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CardFooter from "components/Card/CardFooter.js";
import useDatabase from "../../hooks/useDatabase";
import SavaAlert from "../../components/Dialog/ConfirmDialog";



const styles = {
  typo: {
    paddingLeft: "25%",
    marginBottom: "40px",
    position: "relative"
  },
  note: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    bottom: "10px",
    color: "#c0c1c2",
    display: "block",
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "13px",
    left: "0",
    marginLeft: "20px",
    position: "absolute",
    width: "260px"
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  },
  dropzone: {
    padding: "5%",
    marginBottom: "40px",
    position: "relative",
    color: "yellow",
    backgroundColor: "DimGrey"
  },
};

const useStyles = makeStyles(styles);
const menuHeader = ["id", "title", "description", "category", "price", "nutrition", "imageURL"];

export default function TypographyPage() {
  useDatabase();
  const classes = useStyles();
  const { state, setMenu } = useContext(OrderContext);
  const [newMenu, setNewMenu] = useState({});
  const text = state.text;


  const onDrop = useCallback(acceptedFiles => {
    // Do something with the files
    const reader = new FileReader()


    reader.onabort = () => console.log('file reading was aborted')
    reader.onerror = () => console.log('file reading has failed')
    reader.onload = () => {
      // Do whatever you want with the file contents
      const binaryStr = reader.result

      csv.csv({
        noheader: false,
        output: "json"
      })
        .fromString(binaryStr)
        .then((csvJson) => {
          let isValid = true;
          let menu = {};

          csvJson.forEach(x => {

            isValid = isValid && Object.keys(x).every((each) => menuHeader.includes(each));

            if (!menu[x.category]) {
              menu[x.category] = [];
            }

            if (menu[x.category]) {
              menu[x.category].push(x);
            }

          });

          if (isValid) {
            setNewMenu(menu);
          }
          else {
            alert('Invalid file header. Please use ' + menuHeader)
          }

        }).catch(err => console.log(err));
    }

    acceptedFiles.forEach(file => reader.readAsBinaryString(file))

  }, [])
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="rose">
            <h4 className={classes.cardTitleWhite}>{text["Menu upload/update"]}</h4>
            <p className={classes.cardCategoryWhite}>
              {text["upload_title"]}
            </p>
          </CardHeader>
          <CardBody>
            <div className={classes.dropzone}  {...getRootProps()} >

              <input {...getInputProps()} />
              {
                isDragActive ?
                  <p>{text["droppzone_title"]} ...</p> :
                  <p>{text["droppzone_title_2"]}</p>
              }
            </div>

          </CardBody>
          {
            !!Object.keys(newMenu).length && <CardFooter stats>
              <span />
              <SavaAlert
                buttonTitle={text["Save"]}
                title="Realtime menu update"
                confirmMessage="The new menu will permanently replace the existing one. This means the users will see the update instantly"
                cancel="Cancel"
                ok={text["Save"]}
                okCallback={() => {
                  setMenu(state.client.id, newMenu);
                  setNewMenu({});
                }}
              />
            </CardFooter>
          }
        </Card>
      </GridItem>
      {
        Object.keys(newMenu).map(category => {
          return (
            <GridItem key={category} xs={12} sm={12} md={12}>
              <Card plain>
                <CardHeader plain color="warning">
                  <h4 className={classes.cardTitleWhite}>{category}</h4>
                  <p className={classes.cardCategoryWhite}>{text["Options"]}: {newMenu[category].length}</p>
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    {
                      newMenu[category].map(item => {
                        return (
                         // <OrderItem key={item.id} item={item} />
                          <MenuItem key={item.id} item={item} />
                        );
                      })
                    }
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
          );
        })
      }

    </GridContainer>
  );
}
