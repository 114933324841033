import { localeMoment } from "./message"
import numeral from "numeral";

export const moment = localeMoment()


export const getTimestamp = (order) => {
    return parseInt(order.id.split("@")[0]);
}


const calculateTotalUnformatted = (order) => {
    const currency = order.items[0].price.split(" ")[1];
    let total = order.items.reduce((total, item) => total + (item.quantity * parseFloat(item.price.split(" ")[0])), 0);
    return ({ total, currency })
}

export const calculateTotal = (order) => {
    const result = calculateTotalUnformatted(order);
    const total = numeral(result.total).format('$0,0.00').replace("$", "");
    return ({ total, currency: result.currency })
}


export const formatOrder = (order) => {
    if (order === undefined) {
        return "";
    } else {
        const total = calculateTotal(order);
        const time = moment(getTimestamp(order)).fromNow();
        return `[ #${order.tableId} ]: ${total.total} ${total.currency}  ${time}`;
    }
}

export const formatPrice = (price) => {
    const result = price.split(" ");
    const value = numeral(parseFloat(result[0])).format('$0,0.00').replace("$", "");
    return value + " " + result[1];

}

export const formatTime = (order) => {
    return moment(getTimestamp(order)).fromNow();
}

export const ordersOptimization = (orders) => {

    const groupById = orders.filter(order => orderStatus(order) !== "Completed").reduce((result, order) => {

        order.items.forEach(item => {
            let value = result[item.id];
            if (!value) {
                value = { "Queue": 0, "In progress": 0 };
            }
            const status = (!!item.status) ? item.status : "Queue"
            value[status] += 1;
            result[item.id] = value;
        })
        return result;
    }, {});

    return groupById;
}

export const houryData = (orders, queuName, timestamp = Date.now()) => {

    const data = [30, 25, 20, 15, 10, 5, 0].map(each => {
        const time = timestamp - (each * 1000 * 60);
        const slot = moment(time).format('LT');
        const quantity = orders.filter(order => orderStatus(order) === queuName && getTimestamp(order) <= time).length
        return ({ slot, quantity })
    });

    return data
}


export const orderRate = (data) => {

    const last = data[data.length - 1].quantity;
    const previous = data[data.length - 2].quantity;
    const rate = (!!last) ? Math.round((last - previous) / last) * 100 : 0
    return rate;
}

export const calculateWaitngTime = (orders, time) => {
    let waitingTime = 0;
    const times = orders.map(order => getTimestamp(order)).sort((a, b) => a - b);
    if (times.length > 0) {
        waitingTime = time - times[0];
        waitingTime = Math.round(waitingTime / (1000 * 60))
    }
    return waitingTime;
}

export const calculateTransactionByUser = (userId, orders) => {
    const result = orders.filter(order => order.id.includes(userId));
    return calculateOrdersTotal(result);
}


export const calculateTransactionByTable = (tableId, orders) => {
    const result = orders.filter(order => order.tableId === tableId);
    return calculateOrdersTotal(result);
}

const calculateOrdersTotal = (orders) => {
    return orders.map(order => calculateTotalUnformatted(order))
        .reduce((accumulation, value) => {
            accumulation.total += value.total;
            accumulation.currency = value.currency;
            return accumulation;
        }, { total: 0, currency: "" });

}


export const getTransactionByUser = (userId, orders) => {
    let userOrders = orders.filter(order => order.id.includes(userId));
    const transaction = calculateTransactionByUser(userId, orders);
    const transactionId = Date.now() + "@" + userId.replace("@", "");
    return { orders: userOrders, ...transaction, transactionId }
}

export const getTransactionByTable = (tableId, orders) => {
    let userOrders = orders.filter(order => order.tableId === tableId);
    const transaction = calculateTransactionByTable(tableId, orders);
    const transactionId = Date.now() + "@" + tableId.replace("@", "");
    return { orders: userOrders, ...transaction, transactionId }
}

export const groupOrderByTable = (orders) => {
    let orderGroup = {};
    orders.forEach(order => {
        if (!orderGroup[order.tableId]) {
            orderGroup[order.tableId] = []
        }
        orderGroup[order.tableId].push(order);
    });

    return orderGroup;
}

export const groupOrderByUser = (orders) => {
    let orderGroup = {};
    orders.forEach(order => {
        const id = order.id.split("@")[1];
        if (!orderGroup[id]) {
            orderGroup[id] = [];
        }
        orderGroup[id].push(order);
    });

    return orderGroup;
}


export const calculateBillis = (orders) => {
    let orderByTable = groupOrderByTable(orders);
    const bills = Object.keys(orderByTable).map(tableId => {
        const transaction = calculateOrdersTotal(orderByTable[tableId]);
        const orderByUser = groupOrderByUser(orderByTable[tableId]);
        const subTransactions = Object.keys(orderByUser).map(userId => {
            const subTransaction = calculateOrdersTotal(orderByUser[userId]);
            return { tableId, subTransaction, userId };
        });
        return { tableId, transaction, subTransactions };
    });

    return bills;
}

export const orderItemList = (orders, queuName) => {
    let category = new Set();
    let id = {};
    let items = [];
    orders.forEach(order => {
        order.items.filter(item => {
            if (item.status === undefined && queuName === "Queue") {
                return true;
            } else {
                return item.status === queuName;
            }
        }).forEach(item => {
            category.add(item.category);
            id[item.id] = item.title;
            items.push({
                table: order.tableId,
                item,
                orderId: order.id
            })
        });
    });

    return { items, category: [...category], id };
}

export const waitingTime = (timestamp, orderId) => {
    const orderTime = parseInt(orderId.split("@")[0]);
    const waitng = Math.round((timestamp - orderTime) / (1000 * 60));
    return waitng;
}

export const calculateItemImpressions = (orders, itemId) => {
    return orders.map(order => {
        const item = order.items.find(x => x.id === itemId);
        if (item && item.status !== "Completed") {
            return item.quantity;
        }
        return 0;
    }).reduce((sum, value) => sum + value, 0);
}

export const orderStatus = (order) => {

    let isSameStatus = true;
    let count = 0;
    let status = order.items[0].status;
    if(!!status){
        while (isSameStatus && (count < order.items.length)) {
            isSameStatus = (status === order.items[count].status);
            count++;
        }
    }
    return (isSameStatus && !!status) ? status : order.status

}
