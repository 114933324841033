import React from "react";
// @material-ui/core
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import OrderQueue from "../Orders/orderQueue";
import HouryOrderChart from "../Orders/hourlyOrderChart";
import useDatabase from "../../hooks/useDatabase";


export default function Dashboard() {
  
  useDatabase();
  
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={6} md={6}>
          <OrderQueue type="Queue" color="danger" />
        </GridItem>
        <GridItem xs={12} sm={6} md={6}>
          <OrderQueue type="In progress" color="warning" />
        </GridItem>
        <GridItem xs={12} sm={6} md={6}>
          <OrderQueue type="Completed" color="success" />
        </GridItem>
        <GridItem xs={12} sm={6} md={6}>
          <OrderQueue type="Billing" color="info" />
        </GridItem>
      </GridContainer>
      <GridContainer>
        <HouryOrderChart queue="Queue" color="danger"/>
        <HouryOrderChart queue="In progress" color="warning"/>
        <HouryOrderChart queue="Completed" color="success"/>
      </GridContainer>
    </div>
  );
}
