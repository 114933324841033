/*!

=========================================================
* Material Dashboard React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import SettingsIcon from "@material-ui/icons/Settings";
import BubbleChart from "@material-ui/icons/BubbleChart";
import TableChart from "@material-ui/icons/TableChart";
import GroupIcon from "@material-ui/icons/Group";
import Accounts from "./views/Notifications/Accounts"
import Unarchive from "@material-ui/icons/Unarchive";
import Language from "@material-ui/icons/Language";
// core components/views for Admin layout
import DashboardPage from "views/Dashboard/Dashboard.js";
import BusinessProfile from "views/UserProfile/UserProfile.js";
import OrderList from "./views/OrderList/OrderList";
import Settings from "views/Settings/Settings.js";
import Icons from "views/Icons/Icons.js";
import Analysis from "../src/views/OrderAnalysis/OrderAnalysis";
//import NotificationsPage from "views/Notifications/Notifications.js";
import UpgradeToPro from "views/UpgradeToPro/UpgradeToPro.js";
// core components/views for RTL layout
import RTLPage from "views/RTLPage/RTLPage.js";

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/admin"
  },
  {
    path: "/orders",
    name: "Orders Manager",
    rtlName: "قائمة الجدول",
    icon: "tune",
    component: OrderList,
    layout: "/admin"
  },
  {
    path: "/analysis",
    name: "Orders Analysis",
    rtlName: "خرائط",
    icon: TableChart,
    component: Analysis,
    layout: "/admin"
  },
  {
    path: "/settings",
    name: "Settings",
    rtlName: "طباعة",
    icon: "app_settings_alt",
    component: Settings,
    layout: "/admin"
  },
  {
    path: "/profile",
    name: "Business Profile",
    rtlName: "ملف تعريفي للمستخدم",
    icon: SettingsIcon,
    component: BusinessProfile,
    layout: "/admin"
  },
  {
    path: "/tutorial",
    name: "Tutorial",
    rtlName: "الرموز",
    icon: BubbleChart,
    component: Icons,
    layout: "/admin"
  },
  {
    path: "/accounts",
    name: "Accounts",
    rtlName: "إخطارات",
    icon: GroupIcon,
    component: Accounts,
    layout: "/admin"
  },/*
  {
    path: "/rtl-page",
    name: "RTL Support",
    rtlName: "پشتیبانی از راست به چپ",
    icon: Language,
    component: RTLPage,
    layout: "/rtl"
  },*/
  {
    path: "/upgrade-to-pro",
    name: "Upgrade To PRO",
    rtlName: "التطور للاحترافية",
    icon: Unarchive,
    component: UpgradeToPro,
    layout: "/admin"
  }
];


export default dashboardRoutes;
