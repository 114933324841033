import React, { useContext, useEffect } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import { ReactComponent as Logo } from '../../logo.svg';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Context as OrderContext } from "../../context/OrderContext";
import GoogleButton from 'react-google-button';
import auth, { loginWithGoogle, emailLinkSignup} from "../../firebase/firebase";
import LoginForm from "../../components/CustomForm/CustomForm";
import Plan from "../UpgradeToPro/UpgradeToPro";
import history from "../../context/HistoryContext";

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://www.v-waiter.com/">
                V-Waiter
      </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(0),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    logo:{ width: 150, height: 150 }
}));

export default function SignIn() {
    const classes = useStyles();
    const { state } = useContext(OrderContext);

    useEffect(() => {
        // Confirm the link is a sign-in with email link.
        if (auth.isSignInWithEmailLink(window.location.href)) {
            // Additional state parameters can also be passed via URL.
            // This can be used to continue the user's intended action before triggering
            // the sign-in operation.
            // Get the email if available. This should be available if the user completes
            // the flow on the same device where they started it.
            let email = window.localStorage.getItem('email');
            if (!email) {
                // User opened the link on a different device. To prevent session fixation
                // attacks, ask the user to provide the associated email again. For example:
             
                email = window.prompt(state.text["email_confirmation"]);

            }
            // The client SDK will parse the code from the link for you.
            auth.signInWithEmailLink(email, window.location.href)
                .then((result) => {
                    // Clear email from storage.
                    window.localStorage.removeItem('email');
                    history.push("/password");
                })
                .catch((error) => {
                    console.log(error)
                });
        }

    }, [state.text]);


    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Logo className={classes.logo} />
                <Plan info={true} />

                 <GoogleButton
                        onClick={() => {
                            loginWithGoogle();
                        }}
                    />

                <LoginForm  isLogin={false} submit={emailLinkSignup} />
                

            </div>
            <Box mt={8}>
                <Copyright />
            </Box>
        </Container>
    );
}