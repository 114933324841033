import React, { useContext } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { Context as OrderContext } from "../../context/OrderContext";
import AddUser from "../../components/Dialog/AddUserDialog";
import UserList from "../../components/Dialog/UserList";
import Update from "../../components/Dialog/UpdateProfile";
import useDatabase from "../../hooks/useDatabase";
import {updateBusinessName,updateLogo,updateTax} from "../../firebase/database";


const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  },
  container:{
    display:"flex",
    flexDirection:"row",
    alignItems:"center",
    justifyContent:"space-between"

  }
};

const useStyles = makeStyles(styles);

export default function UserProfile() {
  useDatabase()
  const { state } = useContext(OrderContext);
  const classes = useStyles();

  if(!state.menu.settings){
    return null;
  }

  const { admin, logo, businessName,tax = 0 } = state.menu.settings;
  const emails = admin.split(",");

  return (
    <div>
      <GridContainer>
        {
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="rose">
                <h4 className={classes.cardTitleWhite}>Business Profile</h4>
                <p className={classes.cardCategoryWhite}>Complete business information</p>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <div className={classes.container}>
                      <CustomInput
                        labelText="Business name"
                        id="company-disabled"
                        formControlProps={{
                          fullWidth: false
                        }}
                        inputProps={{
                          disabled: true,
                          defaultValue: businessName

                        }}

                      />
                      <Update type="Name" title="Please enter the business name" callback={updateBusinessName} />
                    </div>

                  </GridItem>

                  <GridItem xs={12} sm={12} md={12}>
                  <div className={classes.container}>
                    <CustomInput
                      labelText="Contact Email"
                      id="email-address"
                      formControlProps={{
                        fullWidth: false
                      }}
                      inputProps={{
                        disabled: true,
                        defaultValue: emails[0]

                      }}
                    />
                    <Update type="Email" title="Please enter the business email" callback={() => { }} />
                    </div>
                  </GridItem>
                   
                  <GridItem xs={12} sm={12} md={12}>
                    <div className={classes.container}>
                      <CustomInput
                        labelText="Tax (%)"
                        id="tax"
                        formControlProps={{
                          fullWidth: false
                        }}
                        inputProps={{
                          disabled: true,
                          defaultValue: tax

                        }}

                      />
                      <Update type="Tax" title="Please enter the business name" callback={updateTax} />
                    </div>

                  </GridItem>

                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                  <div className={classes.container}>
                    <CustomInput
                      labelText="Logo"
                      id="logo"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        defaultValue: logo

                      }}
                    />
                    
                    <Update type="Logo" title="Please enter the business logo URL" callback={updateLogo} />
                    </div>
                  </GridItem>         
                </GridContainer>
              </CardBody>
              <CardFooter>
              </CardFooter>
            </Card>
          </GridItem>
        }

        <GridItem xs={12} sm={12} md={12}>
          <Card profile>
            <CardAvatar profile>
                <img  src={logo} alt="logo" />
            </CardAvatar>
            <CardBody profile>
              <h6 className={classes.cardCategory}>Users</h6>
              <UserList className={classes.description} emails={emails} />
              <AddUser />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>

    </div>
  );
}
