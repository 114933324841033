import React, { useState, useContext } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Edit from '@material-ui/icons/Edit';
import { Context as OrderContext } from "../../context/OrderContext";

export default ({ type, title, callback }) => {

    const { state } = useContext(OrderContext);
    const [open, setOpen] = useState(false);
    const [text, setText] = useState("");
    const [confirmText, setConfirmText] = useState("");
    const [message, setMessage] = useState(undefined);
    const [color, setColor] = useState("red");

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <IconButton color="primary" onClick={handleClickOpen} aria-label="edit">
                <Edit />
            </IconButton>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Business profile update</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {title}
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="upadate"
                        label={type}
                        type="text"
                        value={text}
                        onChange={(e) => {
                            setMessage(undefined)
                            setText(e.target.value)
                        }}
                        fullWidth
                    />
                    <TextField
                        margin="dense"
                        id="confirm"
                        label={`Confirm ${type}`}
                        type="text"
                        onChange={(e) => {
                            setMessage(undefined)
                            setConfirmText(e.target.value)
                        }}
                        value={confirmText}
                        fullWidth
                    />
                    {
                        !!message && <strong style={{ color: color }}>{message}</strong>
                    }

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                   </Button>
                    <Button onClick={async () => {
                        if (!!text && (text === confirmText)) {
                            try {
                                const result = await callback(state.client.id, text);
                                if (result.success) {
                                    setMessage(`${type} updated successfully`);
                                    setColor("green");
                                    setTimeout(() => {
                                        handleClose();  
                                    }, 1000);
                                } else {
                                    setMessage(`invalid input`);
                                    setColor("red");
                                }

                            } catch (err) {
                                setMessage(`invalid input`);
                                setColor("red");
                            }
                        } else {
                            setMessage(`Input values do not match`);
                            setColor("red");
                        }
                    }} color="primary">
                        Save
                   </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}