import React, { useContext } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import { ReactComponent as Logo } from '../../logo.svg';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Context as OrderContext } from "../../context/OrderContext";
import history from "../../context/HistoryContext";
import AccountsList from "./AccountList";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { createNewAccount } from "../../firebase/database";
import auth from "../../firebase/firebase";
import { classicNameResolver } from 'typescript';

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://www.v-waiter.com/">
                V-Waiter
      </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(0),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    },
    logo:{ width: 150, height: 150 }
}));

export default function SignIn() {
    const classes = useStyles();
    const { state } = useContext(OrderContext);
    const text = state.text;


    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Logo className={classes.logo} />
            </div>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color="success">
                            <h4 className={classes.cardTitleWhite}>{text["Accounts Overview"]}</h4>
                            <p className={classes.cardCategoryWhite}>{text["Accounts_overview_title"]}</p>
                        </CardHeader>
                        <CardBody>
                            <AccountsList />
                        </CardBody>
                        <CardFooter>
                                <Button onClick={() => history.push("/payment")} color="success">{text["Create Account"]}</Button>
                                {
                                    false && <Button onClick={() => {
                                        createNewAccount(auth.currentUser.email, auth.currentUser.uid).then((value) => {
                                            console.log(value);
                                        })
                                    }}
                                        color="success">
                                        New account
                                    </Button>
                                }
                        </CardFooter>
                    </Card>
                </GridItem>
            </GridContainer>
            <Box mt={8}>
                <Copyright />
            </Box>
        </Container>
    );
}