import createContext from "./createContext";
import { localeMessage as message } from "../api/message";
import { updateStatus, updateMenu } from "../firebase/database";
import auth from "../firebase/firebase";
import {orders} from "../tests/Fixtures/orders"


const initialState = {
    orders: [],//orders,
    notification: [{ id: "0", message: "Welcome" }],
    client: {},
    user: undefined,
    text: message("en"),
    selectedOrder: undefined,
    isSelectedOrderModified: false,
    menu: {},
    completedTimestamp: 1596572582116,
    accounts: [],
    settings: {}
};


const resetState = {
    orders: [],
    notification: [{ id: "0", message: "Welcome" }],
    client: {},
    user: undefined,
    text: message("en"),
    selectedOrder: undefined,
    isSelectedOrderModified: false,
    menu: {},
    completedTimestamp: 1596572582116,
    accounts: [],
    settings: {}
};

const reducer = (state, action) => {
    let orders;
    switch (action.type) {
        case "ADD_ORDER":
            return { ...state, orders: [...state.orders, action.payload] };
        case "REMOVE_ORDER":
            orders = state.orders.filter(order => order.id === action.payload.id)
            return { ...state, orders };
        case "MODIFY_ORDER":
            orders = state.orders.map(order => {
                if (order.id === action.payload.id) {
                    return action.payload;
                }
                return order;
            });
            return { ...state, orders };
        case "LOAD_ORDERS":
            return { ...state, orders: action.payload };
        case "SET_MENU":
            return { ...state, menu: action.payload };
        case "SET_TEXT":
            return { ...state, text: action.payload };
        case "COMPLETED_TIMESTAMP":
            return { ...state, completedTimestamp: action.payload };
        case "SET_USER":
            return { ...state, user: action.payload };
        case "SET_CLIENT":
            return { ...state, client: action.payload };
        case "SET_SETTINGS":
            return { ...state, settings: action.payload };
        case "SET_ACCOUNTS":
            return { ...state, accounts: action.payload };
        case "SET_SELECTED_ORDER":
            return { ...state, selectedOrder: action.payload, isSelectedOrderModified: false };
        case "SHOW_EDIT":
            return { ...state, showEdit: action.payload };
        case "UPDATE_MENU":
            return { ...state, menu: action.payload };
        case "UPDATE_SELECTED_ORDER":
            return { ...state, selectedOrder: { ...state.selectedOrder, ...action.payload }, isSelectedOrderModified: true };
        case "LOG_OUT":
            return { ...state, ...resetState };
        case "CHANGE_STATUS":
            orders = state.orders.map(order => {

                if (order.id === action.payload.id) {
                    return { ...order, status: action.payload.status };
                }
                return order;
            })
            return { ...state, orders };
        default:
            return state;
    }
}

const actions = {
    addOrder: (dispatch) => (order) => dispatch({ type: "ADD_ORDER", payload: order }),
    setCompletedTimestamp: (dispatch) => (timestamp) => dispatch({ type: "COMPLETED_TIMESTAMP", payload: timestamp }),
    modifyOrder: (dispatch) => (order) => dispatch({ type: "MODIFY_ORDER", payload: order }),
    removeOrder: (dispatch) => (order) => dispatch({ type: "REMOVE_ORDER", payload: order }),
    setUser: (dispatch) => (user) => dispatch({ type: "SET_USER", payload: user }),
    setSelectedOrder: (dispatch) => (orders) => dispatch({ type: "SET_SELECTED_ORDER", payload: orders }),
    updateSelectedOrder: (dispatch) => (update) => dispatch({ type: "UPDATE_SELECTED_ORDER", payload: update }),
    setText: (dispatch) => (locale) => dispatch({ type: "SET_TEXT", payload: message(locale) }),
    loadOrders: (dispatch) => (orders) => dispatch({ type: "LOAD_ORDERS", payload: orders }),
    setClient: (dispatch) => (clientId) => dispatch({ type: "SET_CLIENT", payload: clientId }),
    setAccounts: (dispatch) => (accounts) => dispatch({ type: "SET_ACCOUNTS", payload: accounts }),
    showEdit: (dispatch) => (value) => dispatch({ type: "SHOW_EDIT", payload: value }),
    changeStatus: (dispatch) => {
        return (clientId, id, status) => {
            updateStatus(clientId, id, status).then((value) => {
                if (value.success) {
                    dispatch({ type: "CHANGE_STATUS", payload: { id, status } });
                }
            })
        }
    },
    setMenu: (dispatch) => {
        return (clientId, menu) => {
            updateMenu(clientId, menu).then((value) => {

            })
        }
    },

    updateMenuChanges: (dispatch) => {
        return (menu) => {
            dispatch({ type: "UPDATE_MENU", payload: menu });
        }
    },


    logOut: (dispatch) => {
        return () => {
            auth.signOut().then(() => {
               dispatch({ type: "LOG_OUT" });
            }).catch((err) => {
                console.log({ err })
            })
        }
    }
}








export const { Provider, Context } = createContext(reducer, actions, initialState)

