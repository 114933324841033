import React, { useState, useContext } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { addUser } from "../../firebase/database";
import { Context as OrderContext } from "../../context/OrderContext";

export default ({isOpen = false}) => {

  const { state } = useContext(OrderContext);
  const text = state.text;

  const [open, setOpen] = useState(isOpen);
  const [email, setEmail] = useState("");
  const [confirmEmail, setConfirmEmail] = useState("");
  const [message, setMessage] = useState(undefined);
  const [color, setColor] = useState("red");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
      setOpen(false);
  };

  return (
    <div>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Add new user
      </Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">New User</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter the email address of the new user. We will send an email
            to the new user to complete the registation.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="email"
            label="Email Address"
            type="email"
            value={email}
            onChange={(e) => {
              setMessage(undefined)
              setEmail(e.target.value)
            }}
            fullWidth
          />
          <TextField
            margin="dense"
            id="confirmEmail"
            label="Confirm Email Address"
            type="email"
            onChange={(e) => {
              setMessage(undefined)
              setConfirmEmail(e.target.value)
            }}
            value={confirmEmail}
            fullWidth
          />
          {
            !!message && <strong style={{ color: color }}>{message}</strong>
          }

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={async () => {
            if (!!email && (email === confirmEmail)) {
             const result = await addUser(state.client.id, email)
             if(result.outcome){
              setMessage(`${email} added successfully`);
              setColor("green");
             }else{
              setMessage(`invalid input`);
              setColor("red");
             }
            } 
          }} color="primary">
            Add New User
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}