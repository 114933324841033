import React, { useContext, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Link } from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Context as OrderContext } from "../../context/OrderContext";
import history from "../../context/HistoryContext"



const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(0),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));



export default ({ isLogin, submit, registrationEmail }) => {
    const classes = useStyles();
    const { state } = useContext(OrderContext);
    const [message, setMessage] = useState("")
    const [color, setColor] = useState("blue")
    const text = state.text;

    if(message === "Login successfully"){
        history.push("/accounts");
    }

    const excuteFormSubmission = async (email, password) => {
        const result = await submit(email, password);
        if (!result.outcome) {
            setColor("red");
            setMessage("Invalid credentials!");
        } else {
            setMessage(result.message);
            setColor("green");
        }
    }

    return (

        <form onSubmit={(e) => {
            setMessage("");
            e.preventDefault();
            const email = e.target.elements.email.value;
            let password;
            if (e.target.elements.password) {
                password = e.target.elements.password.value;
            }

            if (isLogin) {
                excuteFormSubmission(email, password);
            }
            else {
                let confirmPassword;
                if (e.target.elements.confirmPassword) {
                    confirmPassword = e.target.elements.confirmPassword.value;
                }
                if (password !== confirmPassword) {
                    setMessage("Password does not match");
                    setColor("red");
                } else {
                    excuteFormSubmission(email, password);
                }

            }


        }} className={classes.form} noValidate>

            {
                !registrationEmail && <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label={text["Email Address"]}
                    name="email"
                    autoComplete="email"
                    autoFocus
                />

            }

            {
                !!registrationEmail && <TextField
                    variant="outlined"
                    margin="normal"
                    value={registrationEmail}
                    required
                    fullWidth
                    id="email"
                    label={text["Email Address"]}
                    name="email"
                    autoComplete="email"
                    disabled
                />

            }

            {
                (isLogin || !!registrationEmail) && <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label={text["Password"]}
                    type="password"
                    id="password"
                    autoComplete="current-password"
                />
            }

            {
                !!registrationEmail && <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="confirmPassword"
                    label={text["Confirm Password"]}
                    type="password"
                    id="confirmPassword"
                    autoComplete="current-password"
                />
            }
            <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label={text["Remember me"]}
            />
            {
                !!message && <Typography variant="body2" color="primary" align="center">
                    <strong style={{ color }}>{message}</strong>
                </Typography>

            }
            <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
            >
                {isLogin ? text["Login"] : text["Sign in"]}
            </Button>
            {
                isLogin && <Grid container>
                    <Grid item xs>
                        <Link to="/signup" variant="body2">
                            {text["Forgot password?"]}
                        </Link>
                    </Grid>
                    <Grid item>
                        <Link to="/signup" variant="body2">
                            {text["no_account_signup"]}
                        </Link>
                    </Grid>
                </Grid>
            }

            {
                !isLogin && <Grid container>
                    <Grid item xs>
                        <Link to="/signup" variant="body2">
                            {text["Forgot password?"]}
                        </Link>
                    </Grid>
                    <Grid item>
                        <Link to="/login" variant="body2">
                            {text["have_account_login"]}
                        </Link>
                    </Grid>
                </Grid>
            }
        </form>

    );
}