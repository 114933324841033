import React from "react";
import Icon from "@material-ui/core/Icon";

export default (queue) => {

    switch (queue) {
        case "Queue":
            return () => (<Icon>queue</Icon>)
        case "In progress":
            return () => (<Icon>pending_actions</Icon>);
        case "Completed":
            return () => (<Icon>assignment_turned_in</Icon>);
        case "Billing":
            return () => (<Icon>receipt</Icon>);
        case "Close":
            return () => (<Icon>highlight_off</Icon>);
        default:
            return () => (<Icon>queue</Icon>);
    }

}


export const CustomIcon = ({ type }) => {
    switch (type) {
        case "Queue":
            return (<Icon>queue</Icon>)
        case "In progress":
            return (<Icon>pending_actions</Icon>);
        case "Completed":
            return (<Icon>assignment_turned_in</Icon>);
        case "Billing":
            return (<Icon>receipt</Icon>);
        case "Close":
            return (<Icon>highlight_off</Icon>);
        default:
            return (<Icon>queue</Icon>);
    }
}