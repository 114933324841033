/*!

=========================================================
* Material Dashboard React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import history from "./context/HistoryContext";
import { Router, Switch, Redirect } from "react-router-dom";
import { Provider } from "../src/context/OrderContext";
import PrivateRoute from "./route/privateRoute";
import PublicRoute from "./route/publicRoute";
// core components
import Admin from "layouts/Admin.js";
import Login from "./views/Login/login";
import SignUp from "./views/Signin/SignUp";
import Password from "./views/Signin/Password";
import auth from "./firebase/firebase";
import Accounts from "../src/views/Accounts/Account"
import Payment from "../src/views/Signin/Payment";
import loading from "./assets/img/loading.gif";
import Progress from "../src/components/Progress/Progress"

import "assets/css/material-dashboard-react.css?v=1.9.0";

const styles = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  minHeight: "100vh",
  backgroundColor: "white"
}

const jsx = (
  <div style={styles}>
    <img src={loading} alt="Loading"/>
  </div>
)

ReactDOM.render(jsx, document.getElementById("root"));

const App = () => {

  return (
    <Router history={history}>
      <Provider>
        <Switch>
          <PrivateRoute path="/admin" component={Admin} />
          <PublicRoute path="/login" component={Login} />
          <PublicRoute path="/signup" component={SignUp} />
          <PrivateRoute path="/accounts" component={Accounts} />
          <PrivateRoute path="/payment" component={Payment} />
          <PrivateRoute path="/password" component={Password} />
          <Redirect from="/" to="/admin/dashboard" />
        </Switch>
      </Provider>
    </Router>);
}


let isRendered = false
const render = () => {
  if (!isRendered) {
    ReactDOM.render(<App />, document.getElementById("root"));
    isRendered = false;
  }
}


auth.onAuthStateChanged(function (user) {
  if (user) {
    render();
  } else {
    render();
    //history.push("/login");

  }
});

