import React, { useContext } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { Context as MenuContext } from "../../context/OrderContext";
import useDatabase from "../../hooks/useDatabase";
import AccountsList from "../../views/Accounts/AccountList";
import Button from "components/CustomButtons/Button.js";
import history from "../../context/HistoryContext";

const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    }
};

const useStyles = makeStyles(styles);

export default () => {
    useDatabase();
    const { state } = useContext(MenuContext);
    const text = state.text;
    const classes = useStyles();

    return (
        <div>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color="success">
                            <h4 className={classes.cardTitleWhite}>{text["Accounts Overview"]}</h4>
                            <p className={classes.cardCategoryWhite}>{text["Accounts_overview_title"]}</p>
                        </CardHeader>
                        <CardBody>
                            <AccountsList />
                        </CardBody>
                        <CardFooter>
                        <Button onClick={()=>history.push("/payment")} color="success">{text["Create Account"]}</Button>
                        </CardFooter>
                    </Card>
                </GridItem>
            </GridContainer>
        </div>
    );
}
