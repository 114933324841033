import React, { useContext, useEffect, useState } from "react";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import AccessTime from "@material-ui/icons/AccessTime";
// core components
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { Context as MenuContext } from "../../context/OrderContext";
import Chartist from "chartist";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import { houryData,orderRate } from "api/functions";
import WaitngTime from "./waitingTime";
import { moment } from "../../api/functions"



const useStyles = makeStyles(styles);

export default ({ color, queue }) => {

  const classes = useStyles();

  const { state } = useContext(MenuContext);
  const text = state.text;
  const [houryDataChart, sethouryDataChart] = useState({});
  const [increase, setIncrease] = useState(0);

  

  const title = (queueName) => {
    switch (queueName) {
      case "Queue":
        return text["Queue Orders"];
      case "In progress":
        return text["In progress Orders"];
      case "Completed":
        return text["Completed Orders"];
      default:
        return "";
    }
  }



  useEffect(() => {
    dataChartInitialazation(state.orders, queue);
    const myInterval = setInterval(() => {
      dataChartInitialazation(state.orders, queue);
    }, 60000);

    return () => {
      clearInterval(myInterval)
    }
  }, [state.orders, queue]);

  const dataChartInitialazation = (orders,queue) => {
    const data = houryData(orders, queue);
    let dataSeries = [];
    dataSeries[0] =  data.map( each => each.quantity);
    const sortedSeries = dataSeries[0].sort((a, b) => a - b);
  
    const hourlyData = {
      data: {
        labels: data.map( each => each.slot),
        series: dataSeries
      },
      options: {
        lineSmooth: Chartist.Interpolation.cardinal({
          tension: 0
        }),
        low: 0,
        high: sortedSeries[sortedSeries.length - 1] + 1, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
        chartPadding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 0
        }
      },
      // for animation

    };

    setIncrease(orderRate(data));
    sethouryDataChart(hourlyData);

  }


  return (
    <GridItem xs={12} sm={12} md={6}>
      <Card chart>
        <CardHeader color={color}>
          {
            !!houryDataChart.data && <ChartistGraph
              className="ct-chart"
              data={houryDataChart.data}
              type="Line"
              options={houryDataChart.options}

            />
          }
        </CardHeader>
        <CardBody>
          <h4 className={classes.cardTitle}>{title(queue)}</h4>
          {
            increase >= 0 && <p className={classes.cardCategory}>
              <span className={classes.successText}>
                <ArrowUpward className={classes.upArrowCardCategory} />
                {increase}%
              </span>
              {" "}{text["order increase in the last hour"]}
            </p>
          }
          {
           increase < 0 && <p className={classes.cardCategory}>
              <span className={classes.dangerText}>
                <ArrowDownward className={classes.upArrowCardCategory} />
                {increase}%
              </span>
              {" "}{text["order decrease in the last hour"]}
            </p>
          }

        </CardBody>
        <CardFooter chart>
          {
            queue === "Completed" && <div className={classes.stats}>
              <AccessTime />{text["Last update"]}: {moment(state.completedTimestamp).calendar()}
            </div>
          }
          {
            queue !== "Completed" && <div className={classes.stats}>
              <AccessTime />{text["Waitng time"]}:  <WaitngTime orders={state.orders.filter(order => order.status === queue)} />
            </div>
          }
        </CardFooter>
      </Card>
    </GridItem>
  );
}


