import React, { useContext } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import OrderItem from "../../views/Menu/OrderItem";
import QueueSteps from "../../components/Steps/QueueStep";
import { Context as OrderContext } from "../../context/OrderContext";
import { makeStyles } from '@material-ui/core/styles';
import TableIcon from '@material-ui/icons/PersonPinCircle';
import FavoriteIcon from '@material-ui/icons/Favorite';
import MenuIcon from '@material-ui/icons/MenuBook';
import { calculateItemImpressions} from "../../api/functions";
import { updateOrderItem } from "../../firebase/database";


const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    maxWidth: 500,
  },
  dialog: {
    backgroundColor: "#d9dedb"
  },
  itemInfo: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center"
  },
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#506cfa",
    color: "white",
  },
  spacing: {
    margin: 4
  }
});


export default ({ open, onClose }) => {

  const classes = useStyles();

  const { state} = useContext(OrderContext);
  const text = state.text;
  const selectedOrder = state.selectedOrder;



  if (!selectedOrder) {
    return null;
  }

  const impressions = calculateItemImpressions(state.orders, selectedOrder.item.id);


  const handleClose = () => {
    const index = state.orders.find(order => order.id === selectedOrder.orderId).items.indexOf(selectedOrder.item);
   
    const item = (!!selectedOrder.status) ? { status: selectedOrder.status } : {};

    if (state.isSelectedOrderModified) {
      updateOrderItem(state.client.id, selectedOrder.orderId, index, {...selectedOrder.item,...item})
        .then(value => onClose(false)).catch(err => onClose(false));
    } else {
      onClose(false);
    }
  };



  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle className={classes.dialog} id="scroll-dialog-title">
          <div className={classes.container}>
            <div className={classes.itemInfo}>
              <TableIcon /><span className={classes.spacing}>{selectedOrder.table}</span>
            </div>
            <div className={classes.itemInfo}>
              <MenuIcon /><span className={classes.spacing}>{selectedOrder.item.id}</span>
            </div>
            <div className={classes.itemInfo}>
              <FavoriteIcon /><span className={classes.spacing}>{impressions}</span>
            </div>
          </div>
        </DialogTitle>
        <DialogContent dividers={true}
          className={classes.dialog}
        >
          <OrderItem />
        </DialogContent>
        <DialogActions className={classes.dialog}>
          <QueueSteps handleClose={handleClose} />
        </DialogActions>
      </Dialog>
    </div>
  );
}