import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import { ReactComponent as Logo } from '../../logo.svg';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import GoogleButton from 'react-google-button';
import auth, { loginWithGoogle,loginWithPassword } from "../../firebase/firebase";
import LoginForm from "../../components/CustomForm/CustomForm";
import Plan from "../UpgradeToPro/UpgradeToPro";

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://www.v-waiter.com/">
                V-Waiter
      </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(0),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default () => {
    const classes = useStyles();
    let email = undefined;
    if( auth.currentUser){
        email = auth.currentUser.email;
    }
   

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Logo style={{ width: 150, height: 150 }} />
                <Plan info={true} />

                {
                  !!email && <GoogleButton
                        onClick={() => {
                            loginWithGoogle();
                        }}
                    />
                }
                {
                    !!email && <LoginForm registrationEmail={email} isLogin={false} submit={loginWithPassword} />
                }

            </div>
            <Box mt={8}>
                <Copyright />
            </Box>
        </Container>
    );
}